import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { useSpring, animated } from 'react-spring';
import { useRect } from '@reach/rect';
import { calc, transform, useReducedMotion } from '../../examples/make-it-springy';
import LiveCode from '../../components/live-code';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "its-springtime-in-react-town"
    }}><a parentName="h1" {...{
        "href": "#its-springtime-in-react-town",
        "aria-label": "its springtime in react town permalink",
        "className": "anchor"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`It's Springtime in React Town`}</h1>
    <p>{`One joy of web development is its short feedback loop. Change a CSS property: now it’s red! Seeing my work right in the browser, interacting with it, and being able to send it to friends, makes the web a great platform for creative expressions of all kinds. Many powerful new ways to express creativity and personality have sprung up as the web has grown and evolved.`}</p>
    <p>{`That’s what makes me excited about `}<a parentName="p" {...{
        "href": "https://react-spring.io"
      }}>{`react-spring`}</a>{`, a way to “bring your components to life with simple spring animation primitives”. React’s declarative programming model has empowered web developers around the world to build predictable, interactive user interfaces and react-spring does the same for animations. If you’ve worked with React, you know it makes handling `}<inlineCode parentName="p">{`state`}</inlineCode>{`, data that changes over time, a breeze.`}</p>
    <p>{`Over the course of this post, I'll show you how react-spring makes working with animations as easy as working with state. Let's dive into some code!`}</p>
    <blockquote>
      <span aria-label="idea" role="img">💡</span> All code samples in this post are editable, hack away!
    </blockquote>
    <LiveCode code={`function Button() {
  const [active, setActive] = React.useState(false);
  return (
    <button
      className="springy-button"
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      style={{
        transform: active ? 'scale(1.2)' : 'scale(1)',
      }}
    >
      Hover over me!
    </button>
  );
}`} mdxType="LiveCode" />
    <h2 {...{
      "id": "code-exploration"
    }}><a parentName="h2" {...{
        "href": "#code-exploration",
        "aria-label": "code exploration permalink",
        "className": "anchor"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Code Exploration`}</h2>
    <p>{`To change the `}<inlineCode parentName="p">{`scale`}</inlineCode>{` of a button on hover, I create some state:`}</p>
    <LiveCode code={`const [active, setActive] = React.useState(false);`} disabled mdxType="LiveCode" />
    <p>{`Change that state `}<inlineCode parentName="p">{`onMouseOver`}</inlineCode>{` and `}<inlineCode parentName="p">{`onMouseOut`}</inlineCode>{` of the button`}</p>
    <LiveCode code={`onMouseOver={() => setActive(true)}
onMouseOut={() => setActive(false)}`} disabled mdxType="LiveCode" />
    <p>{`And conditionally set the `}<inlineCode parentName="p">{`transform`}</inlineCode>{` CSS property on the button `}<inlineCode parentName="p">{`style`}</inlineCode>{` based on the `}<inlineCode parentName="p">{`active`}</inlineCode>{` state.`}</p>
    <LiveCode code={`style={{
  transform: active ? 'scale(1.2)' : 'scale(1)',
}}`} disabled mdxType="LiveCode" />
    <h2 {...{
      "id": "theres-no-such-spring-as-bad-publicity"
    }}><a parentName="h2" {...{
        "href": "#theres-no-such-spring-as-bad-publicity",
        "aria-label": "theres no such spring as bad publicity permalink",
        "className": "anchor"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`There's no such Spring as bad publicity`}</h2>
    <p>{`Animating this button is as easy as dropping in a new hook `}<inlineCode parentName="p">{`useSpring`}</inlineCode>{` that reacts to the `}<inlineCode parentName="p">{`active`}</inlineCode>{` state.`}</p>
    <blockquote>
      <span aria-label="idea" role="img">🤔</span> Consider using CSS animations for simple hover interactions like this. We'll build on this example though. Just wait! 😉
    </blockquote>
    <LiveCode scope={{
      useSpring,
      animated
    }} code={`function AnimatedButton() {
  const [active, setActive] = React.useState(false);
  const props = useSpring({
    transform: active ? 'scale(1.2)' : 'scale(1)',
  });
  return (
    <animated.button
      className="springy-button"
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
      style={{
        transform: props.transform,
      }}
    >
      Hover over me!
    </animated.button>
  );
}`} mdxType="LiveCode" />
    <p>{`There are a couple subtle changes to make this work:`}</p>
    <p>{`Add the `}<inlineCode parentName="p">{`useSpring`}</inlineCode>{` hook and move the CSS properties and conditions inside the object it receives. `}<inlineCode parentName="p">{`useSpring`}</inlineCode>{` is imported from `}<inlineCode parentName="p">{`react-spring`}</inlineCode></p>
    <LiveCode code={`const props = useSpring({
  transform: active ? 'scale(1.2)' : 'scale(1)',
});`} disabled mdxType="LiveCode" />
    <p>{`Change the `}<inlineCode parentName="p">{`button`}</inlineCode>{` element into a `}<inlineCode parentName="p">{`animated.button`}</inlineCode>{`. `}<inlineCode parentName="p">{`animated`}</inlineCode>{` is imported from react-spring.`}</p>
    <LiveCode code={`<animated.button>`} disabled mdxType="LiveCode" />
    <p>{`Wire up the "animated" `}<inlineCode parentName="p">{`props`}</inlineCode>{` from `}<inlineCode parentName="p">{`useSpring`}</inlineCode>{` to our buttons `}<inlineCode parentName="p">{`style`}</inlineCode>{` prop.`}</p>
    <LiveCode code={`style={{
  transform: props.transform,
}}`} disabled mdxType="LiveCode" />
    <p>{`And just like that, you've animated your React Component! The above is a pretty simple animation, though, `}<strong parentName="p">{`I'd recommend using CSS animations if you're looking for a simple hover interaction`}</strong>{`. So, let's look at a more involved animation to see where the power of react-spring really kicks in!`}</p>
    <h2 {...{
      "id": "spring-it-on"
    }}><a parentName="h2" {...{
        "href": "#spring-it-on",
        "aria-label": "spring it on permalink",
        "className": "anchor"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Spring It On`}</h2>
    <LiveCode scope={{
      useSpring,
      useRect,
      animated,
      calc,
      transform
    }} code={`function ParallaxAnimatedButton({ rotation = 10, scale = 1.2 }) {
  const buttonRef = React.useRef();
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 7, tension: 500, friction: 40 }
  }));
  return (
    <animated.button
      ref={buttonRef}
      className="springy-button"
      onMouseMove={({ clientX, clientY }) =>
        set({ xys: calc(rotation, scale, clientX, clientY, buttonRef.current) })
      }
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{
        transform: props.xys.to(transform),
      }}
    >
      Hover over me!
    </animated.button>
  );
}`} mdxType="LiveCode" />
    <blockquote>
      <span aria-label="idea" role="img">💡</span> Try editting the default <code>rotation</code> or <code>scale</code> props' value to a larger number and see what happens!
    </blockquote>
    <p>{`Let's walk through the changes needed to make this interaction possible:`}</p>
    <ol>
      <li parentName="ol">{`First, you'll notice I got rid of the `}<inlineCode parentName="li">{`active`}</inlineCode>{` state. If your hover state only affects style in your render, then consider just using CSS.`}</li>
      <li parentName="ol">{`I setup a refs that will be used in calculating the animated button's size and location. This is necessary for the `}<inlineCode parentName="li">{`calc`}</inlineCode>{` function to work. I haven't included that function because, Math. If you want to see it, `}<a parentName="li" {...{
          "href": "https://github.com/infiniteluke/lukeherrington.com/blob/master/src/examples/make-it-springy.js"
        }}>{`check out the source`}</a>{`.`}</li>
    </ol>
    <LiveCode code={`const buttonRef = React.useRef();`} disabled mdxType="LiveCode" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Next, I create the spring. It holds an array of the `}<inlineCode parentName="li">{`x`}</inlineCode>{` rotation, `}<inlineCode parentName="li">{`y`}</inlineCode>{` rotation and the `}<inlineCode parentName="li">{`scale`}</inlineCode>{` values for our transform, respectively. I've also configured the spring's dynamics a bit to make the animation more playful and bouncy. You may notice I'm using `}<inlineCode parentName="li">{`useSpring`}</inlineCode>{` a bit differently. You can optionally destructure a `}<inlineCode parentName="li">{`set`}</inlineCode>{` function for triggering the spring manually.`}</li>
    </ol>
    <LiveCode code={`const [props, set] = useSpring(() => ({
  xys: [0, 0, 1],
  config: { mass: 7, tension: 500, friction: 40 }
}));`} disabled mdxType="LiveCode" />
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`I then add the `}<inlineCode parentName="li">{`buttonRef`}</inlineCode>{` and mouse event handlers to the `}<inlineCode parentName="li">{`animated.button`}</inlineCode>{`.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`In `}<inlineCode parentName="li">{`onMouseMove`}</inlineCode>{` I recalculate and set the new spring value. The calculation is based on the `}<inlineCode parentName="li">{`rotation`}</inlineCode>{` and `}<inlineCode parentName="li">{`scale`}</inlineCode>{` props, which control how dramatic the animation is, the `}<inlineCode parentName="li">{`x`}</inlineCode>{` and `}<inlineCode parentName="li">{`y`}</inlineCode>{` coordinates of the mouse, and the size/location of the button.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onMouseLeave`}</inlineCode>{` sets the spring back to the initial values.`}</li>
    </ul>
    <LiveCode code={`<animated.button
  ref={buttonRef}
  className="springy-button"
  onMouseMove={({ clientX, clientY }) =>
    set({
      xys: calc(
        rotation, // Used to calculate how dramatic the "wobble" effect of the button should be
        scale, // The scale to switch the button should animate to
        clientX, // The mouse's "x" position on the visible screen
        clientY, // The mouse's "y" position on the visible screen
        buttonRef.current, // A reference to the button element to get the offsetX/Y and offsetWidth/Height
      )
    })
  }
  onMouseLeave={() => set({ xys: [0, 0, 1] })}
>
 `} disabled mdxType="LiveCode" />
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Finally, the `}<inlineCode parentName="li">{`transform`}</inlineCode>{` style is set on the button using the springs `}<inlineCode parentName="li">{`to`}</inlineCode>{` function which allows me to transform the spring values into the CSS rotations/scale. ex: `}<inlineCode parentName="li">{`transform: perspective(800px) rotateX(10deg) rotateY(-10deg) scale(1.2)`}</inlineCode>{`.`}</li>
    </ol>
    <LiveCode code={`transform: props.xys.to(transform)`} disabled mdxType="LiveCode" />
    <h2 {...{
      "id": "too-much-of-a-good-spring"
    }}><a parentName="h2" {...{
        "href": "#too-much-of-a-good-spring",
        "aria-label": "too much of a good spring permalink",
        "className": "anchor"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Too much of a good Spring`}</h2>
    <p>{`As you can see, things can get out of hand quite quickly when it comes to animation! react-spring is super powerful. With a couple of hooks, some creativity, and a little math, we made a fun interactive button. But with great power, comes great responsibility.`}</p>
    <p>{`When building animations, it's tempting to put them everywhere and make them extra "springy". However, consider the different kinds of users that may use your site.`}</p>
    <p>{`Vestibular dysfunction, a balance disorder of the inner ear, is surprisingly common among US adults. `}<a parentName="p" {...{
        "href": "https://www.ncbi.nlm.nih.gov/pubmed/19468085"
      }}>{`A study`}</a>{` from the early 2000's found that approximately 69 million Americans had vestibular dysfunction which results in vertigo, nausea, migraines and hearing loss. Many people affected by vestibular dysfunction will choose to set the "Reduce motion" setting in their OS. In macOS it's found in the accessibility settings.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.3846153846154%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAACuElEQVQ4y5VTy07bQBQNi3bbFU/JBEEQgkBCHn7EScg7ODghD8chRZVAFYsuuoBKXXRVKn6CX7D4OyRvMtLcnjvCVtOuaunoeu7MnDn3lUjgu7i4+NDpdJLtdlvr9Xqa67qaZVlauVzWqtWqdn5+rvyVSkX5apWa5g097Xp2rfkTX/MmXpI5EtFXr9efcYkuLy9ffd8PZ7NZCF9YKpVC27ZDx3FCXAhBptZmyQz7g3449afhaDx6nU6n1Gq1nmPC09PTFyihbDYrM5kMMfL5PBmGoWCaJum6HqNu1qlValHTalLDaEi7ZNPx8fFLTIhLwf39A4F4kUwm5ebmpkylUhKvSs/zJFRLKJZQJy3TkqPmSN75d/JmdCNvh7eLWrVG6XQ6iAmLuh748490lE6L1N4ebW9v08HBAZ2dnVG/31dADgmhE9JAdtkmp+uQ03HYJxqNBqWP/iA0TDP4+u07ZbJZsbu7S+vr67QHYhSG+HCz2STkkFAs5YNSMi1TAfuiVvtLoW5awaeHX9RzB6KMw6wCIca5Y6XoAuLC8Zr3I2BP8P4yoWEG3pcfdPv5Tsyvrmg8HtNkMuHKKWXdbpcGgwENh0NFjGorsFI8/K/CTC4fVNsOO4WmabSxsUEoirrEqpgkAq/5IU7DmxV8DlX+I4dFPZi5U06sACnt7OyoonDIhUKBisWisrlcToFbKvIDgpUuEcIZeCOPCvmCODw8lFtbW3J/f1+iNyWmR0KFRNgSYUukQKLqEkpVW0G14HwvhYwXA93Q+ZUFlKkeRHOrvsO4SSRfEc7nc9WTbNFG0f6Ch2JJIXc554df4txwq3CiuXpcVQ4TU0QnJycxeB35+RwExJOysrq66qIQT8BPTMkjrAL/oycf19bWlI38jMgP8J0nWJe5EmhURQq8A96/2f9BdGcFShO/AUZBhpw6o4ojAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Image of reduce motion setting in macos accessibility settings",
          "title": "Image of reduce motion setting in macos accessibility settings",
          "src": "/static/56a2a145993311eb80344c1b9845f23f/8ff1e/reduce-motion-macos.png",
          "srcSet": ["/static/56a2a145993311eb80344c1b9845f23f/9ec3c/reduce-motion-macos.png 200w", "/static/56a2a145993311eb80344c1b9845f23f/c7805/reduce-motion-macos.png 400w", "/static/56a2a145993311eb80344c1b9845f23f/8ff1e/reduce-motion-macos.png 800w", "/static/56a2a145993311eb80344c1b9845f23f/6ff5e/reduce-motion-macos.png 1200w", "/static/56a2a145993311eb80344c1b9845f23f/884f2/reduce-motion-macos.png 1560w"],
          "sizes": "(max-width: 800px) 100vw, 800px",
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`To avoid making your users sick, consider using the `}<inlineCode parentName="p">{`prefers-reduced-motion`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion"
      }}>{`media query`}</a>{` to disable or lessen animations in your app. I even made a hook out of it:`}</p>
    <LiveCode code={`const useReducedMotion = () => {
  const [matches, setMatch] = React.useState(false)
  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    setMatch(mediaQuery.matches);
    const handleChange = () => {
      setMatch(mediaQuery.matches)
    }
    mediaQuery.addEventListener('change', handleChange);
    return () => {
      mediaQuery.removeEventListener('change', handleChange)
    }
  }, []);
  return matches;
}`} disabled mdxType="LiveCode" />
    <blockquote>
      <span aria-label="fire" role="img">🔥</span> I turned this into a library called <a href="https://github.com/infiniteluke/react-reduce-motion">react-reduce-motion</a>. It implements <code>useReducedMotion</code> for web and react native.
    </blockquote>
    <p>{`To use the hook, you can call it at the top of your function component, and use its return value to decide if to apply the animation or the default style.`}</p>
    <blockquote>
      <span aria-label="idea" role="img">💡</span> If your OS supports "Reduce motion", try enabling it and interacting with the button below:
    </blockquote>
    <LiveCode scope={{
      useSpring,
      useRect,
      animated,
      calc,
      transform,
      useReducedMotion
    }} code={`function ParallaxAnimatedButton({ rotation = 10, scale = 1.2 }) {
  const buttonRef = React.useRef();
  const reduceMotion = useReducedMotion();
  const defaultTransform = [0, 0, 1]
  const actualRotation = reduceMotion ? rotation / 3 : rotation;
  const actualScale = reduceMotion ? 1.01 : scale;
  const [props, set] = useSpring(() => ({
    xys: defaultTransform,
    config: { mass: 7, tension: 500, friction: 40 }
  }));
  return (
    <animated.button
      ref={buttonRef}
      className="springy-button"
      onMouseMove={({ clientX, clientY }) =>
        set({ xys: calc(actualRotation, actualScale, clientX, clientY, buttonRef.current) })
      }
      onMouseLeave={() => set({ xys: defaultTransform })}
      style={{
        transform: props.xys.to(transform),
      }}
    >
      Hover over me!
    </animated.button>
  );
}`} mdxType="LiveCode" />
    <blockquote>
      <span aria-label="idea" role="img">💡</span> You could also skip animations all together by setting the <code>skipAnimation</code> Global in react-spring:
    </blockquote>
    <LiveCode code={`import {Globals} from 'react-spring'
const MyApp = () => {
  const prefersReducedMotion = useReducedMotion()
  React.useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion,
    })
  }, [prefersReducedMotion])
  return ...
}`} disabled mdxType="LiveCode" />
    <h2 {...{
      "id": "all-good-springs-come-to-an-end"
    }}><a parentName="h2" {...{
        "href": "#all-good-springs-come-to-an-end",
        "aria-label": "all good springs come to an end permalink",
        "className": "anchor"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`All good Springs come to an end`}</h2>
    <p>{`I'm really excited about react-spring. With very little code, you can add a bit of personality to your app. But, as always, it's imperative that accessibility doesn't suffer for the sake of "cool" interactions. react-spring could bake an accessibility solution into the library by allowing a config option or `}<a parentName="p" {...{
        "href": "https://github.com/react-spring/react-spring/issues/811"
      }}>{`shipping the above hook`}</a>{` to help devs respect the reduce motion media query. Until then, it's on you to make sure your app is usable by everyone!`}</p>
    <p>{`So, go make your app springy with react-spring! But not `}<em parentName="p">{`too`}</em>{` springy... `}<span aria-label="winking emoji" role="img">{`😉`}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      